<template>
  <div class="dropdown dropdown-notifications w500">
    <a href="#" class="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover dropdown-toggle no-caret"
      data-bs-toggle="dropdown" data-dropdown-animation role="button" aria-haspopup="true" aria-expanded="false"><span
        class="icon"><span class="position-relative me-3"><span class="feather-icon"><i
              class="bx bx-bell bx-tada fs-4"></i></span><span
            class="position-absolute top-0 start-100 translate-middle badge badge-success badge-indicator position-top-end-overflow-1"></span></span></span></a>
    <div class="dropdown-menu dropdown-menu-end p-0 w500">
      <h6 class="dropdown-header px-4 fs-6">
        Notifications
        <a href="#" class="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover">
          <span class="icon"><span class="feather-icon"><i class="bx bx-cog"></i></span></span>
        </a>
      </h6>

      <div v-if="notificationsHeader.loading" class="more-notif-spinner w-notif-header">
        <div class="spinner-border text-primary" role="status">
          <!-- <span class="sr-only">Chargement ...</span> -->
        </div>
      </div>

      <div data-simplebar class="dropdown-body p-2" v-else>
        <NuxtLink to="" class="dropdown-item" v-for="(notif, index) in notificationsHeader.data" :key="index"
          @click="handleClick(notif)">
          <div class="media">
            <div class="media-head">
              <div class="avatar avatar-rounded avatar-sm">
                <img src="~/assets/images/avatar2.jpg" alt="user" class="avatar-img" />
              </div>
            </div>
            <div class="media-body">
              <div>
                <div class="notifications-text">
                  <!-- Dr Elisabeth a accepter votre invitation à réjoindre l'équipe. -->
                  {{ notif.message }}
                </div>
                <div class="notifications-info">
                  <!-- <span class="badge badge-soft-success">Collaboration</span> -->
                  <span class="badge badge-soft-success">{{
                    getCustomLabel(notif.label)
                  }}</span>
                  <div class="notifications-time">
                    {{ formatDateTime(notif.createdAt) }}
                  </div>
                  <!-- <div class="notifications-time">Aujourd'hui, 10:14</div> -->
                </div>
              </div>
            </div>
          </div>
        </NuxtLink>

        <div v-if="notificationsHeader.moreNotificationLoading" class="more-notif-spinner">
          <div class="spinner-border text-primary" role="status"></div>
        </div>

        <!-- <div v-if="!notificationsHeader.data.length"></div> -->

        <div v-if="!notificationsHeader.data.length" class="w-empty-data-notif w-empty-header-notif">
          <img src="~/assets/icons/empty-data.svg" alt="" />
          <span>Vous n'avez actuellement aucune notification.</span>
        </div>

        <div class="dropdown-body p-2" v-else>
          <InfiniteLoading @infinite="handleFetchMoreNotificationComponent" :spinner="false" />
        </div>
      </div>
      <div class="dropdown-footer">
        <NuxtLink to="/notifications"><u>Voir tous les notifications</u></NuxtLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import dayjs from "dayjs";
import { storeToRefs } from "pinia";
import { useNotificationStore } from "~/store/notifications/notificationStore";
import { urls } from "~/store/notifications/urls";
import InfiniteLoading from "v3-infinite-loading";
// import "v3-infinite-loading/lib/style.css"; //required if you're not going to override default slots

const { udpateNotification, getMoreNotificationsHeader } =
  useNotificationStore();
const { notificationsHeader } = storeToRefs(useNotificationStore());

const formatDateTime = (dateTime: any) => {
  return dayjs(dateTime).format("DD MMM YYYY à HH:mm");
};

const handleClick = async (item: any) => {
  console.log("item : ", item);
  const url = urls.find((url) => url.label === item.label);
  await udpateNotification(item._id);

  navigateTo(url?.url);
};

const handleFetchMoreNotificationComponent = async () => {
  await getMoreNotificationsHeader();
};

const getCustomLabel = (labe: string) => {
  try {
    const url = urls.find((url) => url.label === labe);

    return url?.name;
  } catch (error) {
    return "";
  }
};

onMounted(async () => {
  await getMoreNotificationsHeader();
});

</script>
<style lang="scss" scoped>
.p-see-more {
  display: flex;
  justify-content: center;
}

.more-notif-spinner {
  display: flex;
  justify-content: center;
  padding: 50px;
}

.more-notif-spinner {
  display: flex;
  justify-content: center;
  padding: 50px;
}

.w-notif-header {
  border: 1px solid;
}

.w-empty-header-notif {
  margin-top: 50px;

  img {
    width: 9rem;
    height: 9rem;
  }
}

.simplebar-content {
  height: 300px !important;
}
</style>
